import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Capacitor} from '@capacitor/core';
import {
  EC_HTTPS_CALLABLE,
  NgPatFirestoreService,
  removeTimestampCTorFromDocumentSnapshot
} from '@gigasoftware/shared/firebase';
import {
  firestoreUserCheckoutSessionsCollection,
  getNgPatCancelPurchaseAction,
  NgPatNormalizedOffer,
  NgPatStripeSubscribePayload,
  selectNgPatStripeCustomerID
} from '@gigasoftware/shared/store';
import {NgPatHtmlHelperService} from '@gigasoftware/shared/utils';
import {Store} from '@ngrx/store';
import {User} from 'firebase/auth';
import firebase from 'firebase/compat';
import {
  addDoc,
  DocumentData,
  DocumentSnapshot,
  onSnapshot
} from 'firebase/firestore';
import {BehaviorSubject, withLatestFrom} from 'rxjs';
import {take} from 'rxjs/operators';

import {APP} from '../../../../entites';
import {StripeStripeErrorModalComponent} from '../../subscriptions-stripe/index';
import {
  CancelWebSubscriptionModalComponent,
  DlcCancelWebModalData
} from '../modals/cancel-web-subscription/cancel-web-subscription-modal.component';
import {DlcWebCheckoutScript} from './dlc-web-pricing.models';

import HttpsCallableResult = firebase.functions.HttpsCallableResult;

// import {loadStripe} from '@stripe/stripe-js/pure';

@Injectable({
  providedIn: 'root'
})
export class DlcWebPricingService {
  isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showCheckoutProgress$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private checkoutDlcWebCheckoutScript: BehaviorSubject<DlcWebCheckoutScript | null> =
    new BehaviorSubject<DlcWebCheckoutScript | null>(null);

  private app: APP | null = null;
  // private webCheckout: Stripe | null = null;

  constructor(
    private htmlHelper: NgPatHtmlHelperService,
    private dialog: MatDialog,
    private store: Store,
    private customFirestore: NgPatFirestoreService
  ) {}

  async loadWebCheckoutScript(app: APP) {
    this.app = app;
    if (!this.isLoaded$.value) {
      this.isLoaded$.next(true);
      /**
       * apps/evolving-cognition/firebase/functions/src/index.ts:213
       */
      const webCheckoutScript = this.customFirestore.httpsCallable(
        EC_HTTPS_CALLABLE.STRIBE_WEB_CHECKOUT_SCRIPT
      );

      webCheckoutScript({
        app,
        env: this.customFirestore.env
      }).then((response: HttpsCallableResult) => {
        if (response && response.data) {
          const data = response.data as DlcWebCheckoutScript;

          this.htmlHelper.upsertScriptInHead(data.scriptParams);
          this.checkoutDlcWebCheckoutScript.next(data);
        }
      });

      // const response: HttpsCallableResult = await webCheckoutScript({
      //   env: this.env,
      //   app
      // });
      //
      // this.webCheckout = await loadStripe((<DlcWebCheckoutScript>response.data).publishableKey);
    }
  }

  /**
   * Checkout Stripe
   */
  checkout(price: NgPatNormalizedOffer) {
    const that = this;

    const checkoutDlcWebCheckoutScript: DlcWebCheckoutScript | null =
      this.checkoutDlcWebCheckoutScript.value;

    if (checkoutDlcWebCheckoutScript) {
      this.htmlHelper.upsertScriptInHead(
        checkoutDlcWebCheckoutScript.scriptParams,
        () => {
          console.log('checkout', price);

          this.customFirestore.user$
            .pipe(
              withLatestFrom(this.store.select(selectNgPatStripeCustomerID))
            )
            .pipe(take(1))
            .subscribe(([user, customerID]: [User, string | null]) => {
              that.createNewSubscription(price.productId, user, customerID);
            });
        }
      );
    }
  }

  async createNewSubscription(
    priceID: string,
    user: User,
    customerID: string | null
  ) {
    console.log('createNewSubscription', priceID, user, customerID);

    const pricePayload: NgPatStripeSubscribePayload = {
      dynamic_tax_rates: [],
      price: priceID,
      quantity: 1
    };

    // https://stripe.com/docs/api/checkout/sessions/create#create_checkout_session-success_url
    // https://docs.stripe.com/js/deprecated/redirect_to_checkout
    // RedirectToCheckoutClientOptions interface from
    const session: any = {
      allow_promotion_codes: true,
      cancel_url: window.location.href,
      line_items: [pricePayload],
      mode: 'subscription',
      price: priceID,
      success_url: window.location.href
      // metadata: {
      //   key: 'value'
      // }
    };

    if (customerID && customerID.length > 0) {
      session.customer = customerID;
    }

    if (user.uid) {
      const docRef = await addDoc(
        this.customFirestore.collectionRef(
          firestoreUserCheckoutSessionsCollection(user.uid)
        ),
        session
      );

      // Wait for the CheckoutSession to get attached by the extension
      onSnapshot(docRef, async (snap: DocumentSnapshot<DocumentData>) => {
        const {error, sessionId} = <any>(
          removeTimestampCTorFromDocumentSnapshot(snap)
        );
        if (error) {
          this.showCheckoutProgress$.next(false);
          // this.showCheckoutProgress$.next(false);
          // Show an error to your customer and then inspect your function logs.
          this.dialog.open(StripeStripeErrorModalComponent, {
            backdropClass: 'dlc-global-dialog-background',
            data: error.message,
            width: '600px'
          });
        }
        if (sessionId) {
          const checkoutDlcWebCheckoutScript: DlcWebCheckoutScript | null =
            this.checkoutDlcWebCheckoutScript.value;

          if (checkoutDlcWebCheckoutScript) {
            // if (this.webCheckout) {
            const {publishableKey, windowClass} = checkoutDlcWebCheckoutScript;

            const stripe = (<any>window)[windowClass](publishableKey);

            if (stripe) {
              // We have a session, let's redirect to Checkout
              // https://stripe.com/docs/js/checkout/redirect_to_checkout
              stripe.redirectToCheckout({sessionId});
            }
          }

          // if (this.webCheckout) {
          //   // We have a session, let's redirect to Checkout
          //   // https://stripe.com/docs/js/checkout/redirect_to_checkout
          //   this.webCheckout.redirectToCheckout({sessionId});
          // }
        }
      });
    }
  }

  cancelWebSubscription(price: NgPatNormalizedOffer) {
    if (price.webPriceSubscriptionId && this.app) {
      /**
       * TODO
       * 1. Create Modal to confirm cancel
       * 2. Modal most not be dismissable
       * 3. Call Firebase Function to cancel subscription
       * 4. Show success or error modal
       */

      this.dialog.open(CancelWebSubscriptionModalComponent, {
        backdropClass: 'dlc-global-dialog-background',
        data: <DlcCancelWebModalData>{
          app: this.app,
          price
        },
        disableClose: true,
        width: '600px'
      });
    }
  }

  cancelSubscription(price: NgPatNormalizedOffer | null | undefined) {
    if (price) {
      if (Capacitor.isNativePlatform()) {
        this.store.dispatch(getNgPatCancelPurchaseAction(price));
      } else {
        this.cancelWebSubscription(price);
      }
    }
  }
}
