export enum AuthLoginAction {
  LOGIN = 'login',
  LOGGING_IN = 'loggingIn',
  SUCCESS = 'success',
  CANCEL = 'cancel',
  ERROR = 'error'
}

/**
 * Values must match AuthLoginAction
 */
export type AuthLoginStatus =
  | 'login'
  | 'loggingIn'
  | 'success'
  | 'cancel'
  | 'error';

export const authLoginMap: {[key: string]: string} = {
  [AuthLoginAction.CANCEL]: 'Login Cancelled',
  [AuthLoginAction.ERROR]: 'Invalid Login',
  [AuthLoginAction.LOGGING_IN]: 'Logging In',
  [AuthLoginAction.LOGIN]: 'Sign In',
  [AuthLoginAction.SUCCESS]: 'Login Success'
};

export interface LoginHeader {
  CANCEL: string;
  ERROR: string;
  LOGGING_IN: string;
  LOGIN: string;
  SUCCESS: string;
}

export function currentLoginState(action: AuthLoginAction): string {
  return authLoginMap[action] as string;
}
/**
 * Copy value to apps/evolving-cognition/firebase/functions/src/models.ts:11
 */
export interface OTLIDFirestore {
  action: AuthLoginStatus;
  displayName: string | null;
  email: string | null;
  jwtToken: string | null;
  otlid: string;
  uid: string | null;
}

export const OTLID_QUERY_PARAM = 'otlid';

/**
 * Copy value to apps/evolving-cognition/firebase/functions/src/models.ts:11
 */
const FIRESTORE_OT_AUTH_CODE_COLLECTION = 'otLoginAuthCodes';

export function firestoreOtlidCollection(): string {
  return `${FIRESTORE_OT_AUTH_CODE_COLLECTION}`;
}

export function firestoreOtlidById(otlid: string): string {
  return `${FIRESTORE_OT_AUTH_CODE_COLLECTION}/${otlid}`;
}
